import { customersModule } from '~/customers';
import { officesModule } from '~/offices';
import { ordersModule } from '~/orders';
import { organizationsModule } from '~/organizations';
import { rmsModule } from '~/rms';
import { AppShell, handleWindowErrors } from '~/root';
import { sentryInit } from '~/root/sentry';
import { shiftsModule } from '~/shifts';
import { subscriptionsModule } from '~/subscriptions';
import { teamsModule } from '~/teams';
import { uiKitModule } from '~/ui-kit';
import { usersModule } from '~/users';
handleWindowErrors();
sentryInit();
AppShell(ordersModule, usersModule, teamsModule, officesModule, customersModule, subscriptionsModule, rmsModule, shiftsModule, uiKitModule, organizationsModule);
